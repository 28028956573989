<template src="@/views/TermsAndConditions/TermsAndConditions-template.vue"></template>
<style src="@/views/TermsAndConditions/TermsAndConditions.css"></style>

<script>
import Title from '@/components/global/Title.vue';
export default {
    name: 'TermsAndConditions',
    components: {
        Title
    }
}
</script>