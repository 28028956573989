<template>
    <div class="container pt-4">
        <Title title="Terminos y condiciones" class="mb-4"></Title>

            <div class="block text-xiii">
                <p class="marg:xii.sr">Los presentes términos y condiciones (denominados los "Términos") rigen el acceso y uso por parte de usted (en adelante, el "Usuario") del sitio web www.terminaldesantamarta.com y a nuestras aplicaciones móviles. El Sitio Web es de propiedad de la Terminal de Santa Marta entidad Privada.</p>
                <p class="marg:xii.sr">Sírvanse leer nuestros Términos cuidadosamente antes de usar nuestros productos, el cual funciona como un mercado en línea. Para fines de claridad, mediante el presente se establece que la <b>Terminal de santa Marta / Redbus</b>, el sitio es una plataforma que simplemente conecta a diversos operadores de buses con los pasajeros de dichos buses. <b>Terminal de santa Marta / Redbus</b> pone a disposición de los Usuarios el Sitio Web el cual muestra una gran cantidad de Proveedores de Servicios de Transporte (en adelante “los Proveedores” o “los operadores de buses”), los cuales ofrecen y comercializan sus servicios de transporte a los Usuarios a través del Sitio Web. En ese sentido, <b>Terminal de santa Marta / Redbus</b> es un intermediario entre el Usuario/Comprador y los Proveedores de los Servicios de Transporte, y brinda sus plataformas o Sitio Web para que éstos concreten las transacciones. <b>Terminal de santa Marta / Redbus</b> y los Proveedores, son empresas independientes por lo que no son empresas afiliadas, y el uso de la plataforma por los Proveedores no supone ningún joint venture o asociación entre ellas.</p>
                <p class="marg:xii.sr">Se deja constancia que <b>Terminal de santa Marta / Redbus</b> no es el proveedor de los Servicios de Transporte, por lo que se aclara que <b>Terminal de santa Marta / Redbus</b> actúa en nombre y por cuenta de los Proveedores, por lo que <b>Terminal de santa Marta / Redbus</b> no está obligado directa ni indirectamente a ejecutar los Servicios. El Usuario, al contratar los Servicios a través del Sitio Web, <b>Terminal de santa Marta / Redbus</b> es un intermediario y su intervención está limitada a la venta del ticket o boleto a través de la plataforma online.</p>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> le permite navegar por el Sitio Web y adquirir pasajes de bus para usted, su familia y/o terceras personas, sin registrarse en el Sitio Web.
                    <br>
                    Consideramos que usted ha leído entendido y aceptado en forma expresa los presentes Términos, los mismos que regirán para cada transacción deseada de reserva y adquisición de pasajes de bus a través del Sitio Web para todos los efectos, y serán vinculantes para Usted. Todos sus derechos y obligaciones con respecto al uso que Usted haga del Sitio Web y para disponer de la facilidad de reserva y adquisición de pasajes de bus, lo mencionado anteriormente se regirán por los presentes Términos.</p>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> no tiene injerencia en el cobro de penalidades, cobros administrativos de endoso de boletos, por parte de los Proveedores o diferencias de tarifas en caso sean aplicables, de acuerdo a las condiciones y términos de Servicio de cada Proveedor.</p>
                <p class="marg:xii.sr">Al aceptar estos Términos, Usted manifiesta su conformidad, además, con las demás políticas de Redbus relativas al Sitio Web; incluyendo pero sin limitarse a la:</p>
                <ul class="padd:xv.oe marg:col-xii marg:xii.sr">
                    <li>1. Política de Privacidad;</li>
                    <li>2. la Política sobre Cookies, vigentes al momento de realizar la transacción.</li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold">1. Terminología:</h2>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li>A efectos de los presentes Términos, donde el contexto así lo requiera, "Usted" o "Usuario" (que significarán el usuario de 'Terminal de santa Marta / Redbus'), significarán cualquier persona natural o jurídica que haya acordado ser comprador de los pasajes de bus de algún Proveedor y/o un usuario de la Facilidad que está siendo ofrecida en el Sitio Web.</li>
                    <li>Contando con seccion informativa </li>
                    <li>Los términos de nuestros servicios significarán <b>Terminal de santa Marta / Redbus</b> y/o sus afiliadas.</li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold">2. Nuestro Sitio Web para compra de tiquetes y pasaporte de bus:</h2>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> es un intermediario en línea de pasajes de bus y contenido informativo, que simplemente conecta a los operadores de buses (transportistas o Proveedores) con los pasajeros (Usuarios o Consumidores). En virtud de lo anterior, Redbus desea indicar lo siguiente:</p>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li>A efectos de los presentes Términos, donde el contexto así lo requiera, "Usted" o "Usuario" (que significarán el usuario de 'Terminal de santa Marta / Redbus'), significarán cualquier persona natural o jurídica que haya acordado ser comprador de los pasajes de bus de algún Proveedor y/o un usuario de la Facilidad que está siendo ofrecida en el Sitio Web.</li>
                    <li>Los términos de nuestros servicios significarán <b>Terminal de santa Marta / Redbus</b> y/o sus afiliadas.</li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">3. Nuestro Sitio Web para información de noticias gubernamentales:</h2>
                <p class="marg:xii.sr">En caso de evidenciar fallas en la entrega del ejemplar o noticia informativa, en el acceso al contenido impreso o digital,
                    o de presentar quejas o reclamos respecto a su inconformidad con lo publicado. El siguiente enlace le proporcionara contatarnos y atender su peticion en caso de que lo redactado por el Lector o Usuario de la plataforma de Noticias</p>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li><a href="https://terminaldesantamarta.com/contactar" target="_blank">https://terminaldesantamarta.com/contactar</a></li>
                    <li>EL LECTOR / Usuario, se compromete y obliga con
                        PUBLICACIONES Terminal de Santa Marta. a no distribuir masivamente, ni de manera indebida y/o ilegal, el
                        contenido exclusivo digital ni el contenido publicado en la revista Nuestra seccion de noticias, que puedan perjudicar e
                        infringir los derechos de autor que PUBLICACIONES Terminal de Santa Marta. ostenta sobre estos productos.</li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">4. Privacidad:</h2>
                <p class="marg:xii.sr">Las políticas internas de <b>Terminal de santa Marta / Redbus</b> ligadas a la privacidad de la información se encuentras desarrolladas en los siguientes documentos que se encuentran a su disposición:</p>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li><a href="https://terminaldesantamarta.com/ver-transparencia-acceso/avisoPrivacidad" target="_blank">La 'Política de Privacidad' del Sitio Web</a></li>
                    <li><a href="https://terminaldesantamarta.com/ver-transparencia-acceso/politicasCookies" target="_blank">La 'Política sobre Cookies' del Sitio Web</a></li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">5. Enlaces:</h2>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li>Este Sitio Web o app movil podrá contener vínculos con otros sitios web mantenidos por terceros. Dichos Sitios Externos están vinculados con el Sitio Web solo para su referencia y comodidad.</li>
                    <li>Usted no deberá vincular su propio sitio web o el sitio web de cualquier Tercero a este Sitio Web sin obtener la previa aprobación por escrito de <b>Terminal de santa Marta / Redbus</b>.</li>
                    <li>El uso de cualquier captura de pantalla automatizada o de tecnologías de adaptación de pantalla con el fin de obtener información de este Sitio Web o Aplicacion movil sin la previa aprobación por escrito de <b>Terminal de santa Marta / Redbus</b>, constituye una violación de nuestros derechos de propiedad intelectual.</li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">6. Correspondencia / Notificaciones:</h2>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li>Cuando Usted utilice el Sitio Web o envíe correos electrónicos u otros datos, información o comunicación a <b>Terminal de santa Marta / Redbus</b>, Usted acuerda y comprende que se está comunicando con nosotros a través de registros electrónicos y autoriza recibir comunicaciones a través de los medios electrónicos de <b>Terminal de santa Marta / Redbus</b> en forma periódica, como y cuando se requiera.</li>
                    <li>Nosotros podremos comunicarnos con Usted mediante correo electrónico / SMS o mediante otro modo de comunicación que nos proporcione y en la medida que lo haya autorizado. En cualquier momento, usted podrá otorgarnos, retirar o conceder nuevamente dicho consentimiento.</li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">7. Exclusión de Garantías y de Responsabilidad</h2>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li>Este Sitio Web / Aplicacion, y los servicios inherentes al mismo incluidos o de otro modo puestos a disposición de Usted a través de este Sitio Web / Aplicacion, a excepción de los Servicios de Transporte que son provistos por los Proveedores de Transporte de acuerdo a estos Términos, son provistos sobre la base de "como están" y "según su disponibilidad", al momento de contratar o utilizar el Sitio Web / Aplicacion, salvo como en otro sentido lo especifique <b>Terminal de santa Marta / Redbus</b> por escrito o en el Sitio Web / Aplicacion.</li>
                    <li>Sin perjuicio del párrafo precedente, el Sitio Web no garantiza que:</li>
                    <ul class="padd:xv.oe">
                        <li>1. El Sitio Web estará constantemente disponible, o disponible en absoluto.</li>
                        <li>2. Que la información proporcionada por los Proveedores de Servicios de Transporte y que se refleja en el Sitio Web esté completa, sea veraz, exacta o no induzca a confusión , pues como se ha detallado en los presentes Términos, la información del Servicio de Transporte le compete exclusivamente a los Proveedores</li>
                    </ul>
                    <li><b>Terminal de santa Marta / Redbus</b> sólo será responsable ante Usted por el contenido, uso y funcionamiento del, o con relación al Sitio Web o Aplicacion. En ese sentido <b>Terminal de santa Marta / Redbus</b> garantiza que este Sitio Web o Aplicacion; la información y el contenido del producto (es decir el software), la Facilidad, los servidores de <b>Terminal de santa Marta / Redbus</b>; y la comunicación electrónica enviada por nosotros son comunicaciones y sitios seguros para el Usuario, de acuerdo con los estándares de la industria. Sin perjuicio de lo cual, <b>Terminal de santa Marta / Redbus</b> manifiesta que a pesar de todos los esfuerzos y altos estándares de seguridad, el Sitio Web o Aplicacion podría estar expuesto a ataques cibernéticos que puedan afectar su funcionamiento y/o seguridad, lo que sería puesto en conocimiento de los Usuarios inmediatamente.</li>
                    <li>Para efectos de reservar boletos de bus a través del Sitio Web, nosotros garantizamos que seremos responsables de las siguientes actividade</li>
                    <ul class="decoration-square">
                        <li>Emitir un boleto válido (un boleto que será previamente aceptado por el operador del bus, es decir, el Proveedor del Servicio)</li>
                        <li>Brindar soporte e información al cliente (Usuario) en caso de cualquier demora en la partida de un bus u otro tipo de inconveniente que pudiese surgir. Esta información se brindará en base a lo que Nos manifieste el Proveedor.</li>
                    </ul>
                    <li>Sin perjuicio de lo indicado previamente, nosotros NO asumimos ninguna responsabilidad por lo siguiente:</li>
                    <ul class="decoration-square">
                        <li>Que el bus de un operador específico (Proveedor) no parta del / arribe al - punto de recojo o de destino a tiempo</li>
                        <li>Que el empleado o los empleados de los operadores de buses sean rudos o descorteses con Usted.</li>
                        <li>Que los asientos del bus del operador o el viaje no estén a la altura de Sus expectativas;
                            Que el operador del bus modifique y/o cancele su reserva de pasaje de bus en forma parcial o total debido a razones inevitables, tal como se describe en las cláusulas siguientes</li>
                        <li>Que su equipaje se extravíe / sea robado / resulte dañado</li>
                        <li>Que el operador del bus cambie el asiento de un cliente a último minuto con el fin de acomodar a una dama, anciano o un niño como pasajeros</li>
                        <li>Que el operador del bus cambie el punto de abordaje y/o emplee un vehículo pick-up en el punto de embarque para llevar a los pasajeros al punto de partida del bus</li>
                        <li>Que el operador del bus no permita equipaje más allá de un límite predeterminado, o de determinadas características, según el exclusivo criterio del operador del bus y que se encuentre detallado en los Términos y Condiciones del operador de bus (Proveedor)</li>
                    </ul>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">8. Política de Cancelación y Reembolso.</h2>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> procesará todo formulario de solicitud de cancelación de boleto de acuerdo con la política de cancelación y/o reembolso del operador del bus (Proveedor). La tarifa de cancelación y el periodo que se tome para procesar el reembolso diferirá según cada operador de bus. Sírvase referirse a la política sobre cancelación del operador del bus.</p>
                <h3 class="text-xiv font-bold marg:xii.sr">¿Cómo cancela el cliente un boleto?</h3>
                <p class="marg:xii.sr">Sírvase a ingresar a nuestro Sitio web o App, dirigirse a la opción Cancelación e iniciar el proceso, cada operador de bus cuenta con su propia política de cancelación; puede contactarnos en el siguiente enlace para brindarle una ascesoria de nuestros servicios. <a href="https://terminaldesantamarta.com/contactar" target="_blank">https://terminaldesantamarta.com/contactar</a></p>
                <h3 class="text-xiv font-bold marg:xii.sr">Política de Reembolso</h3>
                <p class="marg:xii.sr">El Cliente tendrá derecho a un reembolso del 100% del pasaje siempre y cuando el Proveedor no cobre una penalidad por cancelación. El cliente no tendrá derecho a reembolso por la cancelación de los boletos si lo solicita fuera de los tiempos establecidos. El cargo por servicio y seguro de viaje no son reembolsables.</p>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">9. Política de Transporte</h2>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li>Usted es responsable de asegurarse que su fecha, hora y destino de viaje sean correctos antes de solicitar/reservar los boletos de bus o cuando usted seleccione para confirmar su compra de boleto en el Sitio Web. Sírvase verificar la Política de cancelación disponible en el Sitio Web para obtener detalles con respecto a la cancelación y los reembolsos.</li>
                    <li>Usted es exclusivamente responsable de estar en el punto de abordaje designado con anticipación razonable, antes de la hora de la partida. Nosotros no somos responsables si usted incumple con abordar el bus alrededor de la hora en que el bus habrá de partir.</li>
                    <li>Los operadores del bus (Proveedores) se reservan el derecho de denegar el transporte de usted y de su equipaje en el bus por cualquier motivo razonable y/o justificado, incluyendo pero sin limitarse a:</li>
                    <ul class="decoration-square">
                        <li>Portar contrabando, sustancias ilegales, peligrosas o fiscalizadas, equipaje frágil, sobredimensionado o con sobrepeso. Un comportamiento indisciplinado, embriaguez o cualquier otra conducta que el operador del bus considere que afecta a la comodidad o seguridad de los demás pasajeros</li>
                        <li>Que usted sea incapaz de exhibir una identificación válida emitida por el gobierno mientras se encuentra de viaje.</li>
                        <li>Que el pago de su pasaje por el boleto sea fraudulento</li>
                        <li>Que la reserva de su asiento haya sido realizada en forma fraudulenta o ilícita, o haya sido adquirida de una persona no autorizada</li>
                        <li>Que la tarjeta de crédito mediante la cual se efectuó su pago por la reserva del boleto haya sido reportada como perdida o robada</li>
                        <li>Que usted no sea capaz de exhibir un boleto de embarque válido y legible para el viaje que se propone efectuar. El billete de embarque constituye un elemento de prueba del contrato de transporte entre el operador del bus (Proveedor) y el titular del boleto de embarque</li>
                        <li>Que se considere que usted no está médicamente apto para viajar y podría representar un riesgo para la salud de los demás pasajeros a bordo del bus, conforme al exclusivo criterio del operador del bus</li>
                        <li>Que usted no llege a tiempo para abordar, antes de la hora de partida, o incumpla con abordar el bus a la hora de partida del bus</li>
                    </ul>
                    <li>Sin perjuicio de los Términos, el Usuario declara que conoce los términos y condiciones especiales del Proveedor que brindará el Servicio de Transporte. Asimismo, se obliga a revisar los términos del Proveedor antes de la prestación del Servicio.</li>
                    <li>Los menores de edad no estarán permitidos a reservar boletos por sí mismos ni estarán autorizados a viajar solos. Deben estar acompañados al menos por un adulto que cuente con la Autorización Notarial o Judicial de Viaje para Menores.</li>
                    <li>Los niños por encima de la edad de cinco (5) años necesitan contar obligatoriamente con un boleto de bus por el pasaje regular del boleto, a menos que un operador de bus específico lo indique en otro sentido en sus términos y condiciones.</li>
                    <li>Usted es responsable de asegurarse de portar consigo, para el viaje, los documentos personales de identidad que sean v</li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">9. Funcionario Encargado de Atender las Quejas</h2>
                <p class="marg:xii.sr">En caso de cualquier queja de consumidores, y sin perjuicio del Libro de Reclamaciones virtual contenido en el Sitio Web, el Usuario Final podrá contactar a <b>Terminal de santa Marta / Redbus</b> en la dirección de correo que se proporciona a continuación</p>
                <ul class="marg:col-xii marg:xii.sr decoration-square">
                    <li><a href="mailto:centraltsm@terminaldesantamarta.com">centraltsm@terminaldesantamarta.com</a></li>
                </ul>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">10. Pérdidas</h2>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> no será responsable de ninguna pérdida (incluyendo lucro cesante, pérdida de ingresos, contratos, ahorros anticipados, plusvalía o estimación de pérdidas) ni de ninguna otra pérdida indirecta o derivada del mal uso del Sitio Web por parte del Usuario, salvo que la pérdida sea producto del mal funcionamiento del Sitio Web imputable a <b>Terminal de santa Marta / Redbus</b>.</p>
                <p class="marg:xii.sr">El Usuario declara y reconoce que en caso de pérdidas o daños en general, generados por el Proveedor del Servicio de Transporte, el único responsable de indemnizarlos es el Proveedor del Servicio de Transporte y no <b>Terminal de santa Marta / Redbus</b>.</p>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">11. Ley y Jurisdicción Aplicables</h2>
                <p class="marg:xii.sr">Los presentes Términos se regirán e interpretarán de acuerdo con las leyes del Perú. Los tribunales con sede en Santa Marta(Magdalena) Cercado tendrán jurisdicción exclusiva con respecto a cualesquier controversias que se originen de o en relación con los presentes Términos.</p>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">12. Enmienda / Modificación</h2>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> se reserva el derecho de modificar los presentes Términos en cualquier momento, con o sin aviso previo, sin embargo, se dará a conocer en el Sitio Web cualquier modificación a los Términos al momento de que éstos sean cargados o subidos al Sitio Web. <b>Terminal de santa Marta / Redbus</b> podrá solicitar nuevamente su aceptación a los Términos en caso de variación de los mismos.</p>
                <h2 class="text-xiv orange:tx h5 font-weight-bold marg:xii.sr">13. Limitación de Responsabilidad:</h2>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> no será responsable por ningún daño o perjuicio de ninguna clase, incluyendo pero sin limitarse a: los daños directos, indirectos, incidentales, punitivos y consecuentes que se originen por el mal uso, uso indebido o fraudulento del Usuario del sitio Web, las facilidades, el servicio de procesamiento de transacciones, o por la incapacidad de contratar del usuario.</p>
                <p class="marg:xii.sr"><b>Terminal de santa Marta / Redbus</b> será responsable únicamente por los daños o perjuicios directos ocasionados por el mal funcionamiento del Sitio Web imputables a <b>Terminal de santa Marta / Redbus</b>, siempre que el Usurario demuestre el daño directo causado</p>
            </div>
        
    </div>
</template>